const Loading = ({message}) => (
  <div className="mt-5" style={{textAlign: 'center'}}>
    <div className="spinner-border" style={{width: '3rem', height: '3rem'}} role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    <p className="lead">{message}</p>
  </div>
)

export default Loading
