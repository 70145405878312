import { useEffect, useState } from 'react'
import { CSVLink } from "react-csv"

import { Api } from '../Api'
import ErrorPane from './ErrorPane'

const table = 'acs-tract'

const State = () => {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [years, setYears] = useState([])
  const [states, setStates] = useState([])

  const [year, setYear] = useState()
  const [state, setState] = useState()
  const [data, setData] = useState()

  // Load years
  useEffect(() => {
    try {
      const fetchData = async () => setYears(await new Api().getYears({table}))
      fetchData()
    } catch (e) {
      console.log(e)
      setError(e.message)
    }
  }, [])

  // Load states
  useEffect(() => {
    setData()
    setStates([])
    try {
      const fetchData = async () => setStates(await new Api().getStates({table, year}))
      if (year && year !== '') fetchData()
    } catch (e) {
      console.log(e)
      setError(e.message)
    }
  }, [year])


  const generateData = async () => {
    setError()
    setLoading(true)
    setData()
    try {
      setData(await new Api().getStateData({year, state}))
    } catch (e) {
      console.log(e)
      setError(e.message)
    }
    setLoading(false)
  }

  let downloadCard = <></>
  if (data) {
    try {
      downloadCard = (
        <div className="card mt-4">
          <div className="card-body">
            <h5 className="card-title">
              CSV data are ready
            </h5>
            <CSVLink 
              className="btn btn-primary mt-2 me-2"
              download={`${year}-${state}-tract.csv`}
              data={data.tract} 
              target="_blank" 
            >
              Download tract CSV
            </CSVLink>
            <CSVLink 
              className="btn btn-primary mt-2 me-2"
              download={`${year}-${state}-county.csv`}
              data={data.county} 
              target="_blank" 
            >
              Download county CSV
            </CSVLink>
          </div>
        </div>
      )
    } catch (e) {
      console.log("Error generating CSVLink", e)
      downloadCard = <ErrorPane error={e} />
    }
  }

  return (
    <div className="cvs-state">
      <h2>Download State Community Vital Signs</h2>
      <p>Select a state for which to download the Community Vital Signs data:</p>

      <form className="row gy-2 gx-3 align-items-center">
        <div className="col-md-3">
          <label className="form-label" htmlFor="year">Year</label>
          {years && years.length > 0 &&
            <select className="form-select" name="year" 
              disabled={!years} value={year} 
              onChange={(e) => setYear(e.target.value)}
            >
              {years && ([''].concat(years)).map((year) => {
                return <option key={year} value={year}>{year}</option>
              })}
            </select>
          }
          {years && years.length <= 0 &&
            <input className="form-control" type="text" 
              value="Loading years..." disabled readOnly
            />
          }
        </div>
        {year && year !== '' &&
          <>
            <div className="col-md-3">
              <label className="form-label" htmlFor="state">State</label>
              {states && states.length > 0 &&
                <select className="form-select" name="state"
                  disabled={!states} value={state} onChange={(e) => setState(e.target.value)}
                >
                  {states && (states.concat([''])).map((state) => {
                    return <option key={state} value={state}>{state}</option>
                  })}
                </select>
              }
              {states && states.length <= 0 &&
                <input className="form-control" type="text" 
                  value="Loading states for year..." disabled readOnly
                />
              }
            </div>
            <div className="col-md-6">
              <label className="form-label">&nbsp;</label>
              { !loading &&
                <button className="btn btn-primary d-block" type="button"
                  disabled={!year || year === '' || !state || state === ''}
                  onClick={generateData}
                >
                  Generate data
                </button>
              }
              { loading &&
                <button className="btn btn-primary d-block"  disabled>
                  <svg className="svg-loader" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="45"/>
                  </svg>
                  Generating data, please do not leave this page...
                </button>
              }
            </div>
          </>
        }
      </form>
      { error && <ErrorPane error={error} /> }
      { downloadCard }
    </div>
  )
}

export default State;