import { useEffect, useState } from 'react'
import { CSVLink } from "react-csv"

import { Api } from '../Api'

import Loading from './Loading'
import ErrorPane from './ErrorPane'

const retryInterval = 5 * 1000
const table = 'acs-tract'

const isPdfData = ({headers}) => {
  return headers && headers["Content-type"] === "application/pdf"
}

const Search = () => {

  const [ years, setYears ] = useState([])
  useEffect(() => {
    const fetchData = async () => setYears(await new Api().getYears({table}))
    fetchData().catch(console.error)
  }, [])

  const [ searching, setSearching ] = useState(false)
  const [ searchResult, setSearchResult ]= useState()
  const [ year, setYear ] = useState('')
  const [ address, setAddress ] = useState('')
  const [ lat, setLat ] = useState('')
  const [ lon, setLon ] = useState('')
  const [ id, setId ] = useState('')
  const [ error, setError ] = useState()

  const searchParams = () => {
    return {
      lat: lat && lat !== '' ? lat : searchResult.y,
      lon: lon && lon !== '' ? lon : searchResult.x,
      year, id
    }
  }

  const [ loadingDashboard, setLoadingDashboard ] = useState(false)
  const [ dashboardData, setDashboardData ] = useState()
  useEffect(() => {
    if (searchResult && dashboardData) {
      const fetchData = async () => {
        setDashboardData(await new Api().getDashboard(searchParams()))
      }
      if (!isPdfData(dashboardData)) {
        setTimeout(fetchData, retryInterval)
      } else {
        setLoadingDashboard(false)
      }
    }
  }, [dashboardData])

  const [ loadingTractData, setLoadingTractData ] = useState(false)
  const [ countyData, setCountyData ] = useState()
  const [ tractData, setTractData ] = useState()

  const resetSearch = () => {
    setError()
    setSearchResult('')
    setDashboardData()
    setTractData()
  }
  const resetGenerate = () => {
    setError()
    setDashboardData()
    setTractData()
  }
  const searchAddress = async () => {
    resetSearch()
    setSearching(true)
    try {
      setSearchResult(await new Api().geocodeAddress({year, address}))
    } catch (e) {
      console.log(e)
      setError(e.message || e)
    }
    setSearching(false)
  }
  const searchLatLon = async () => {
    resetSearch()
    setSearching(true)
    try {
      setSearchResult(await new Api().geocode({year, lat, lon}))
    } catch (e) {
      console.log(e)
      setError(e.message || e)
    }
    setSearching(false)
  }
  const generateDashboard = async () => {
    resetGenerate()
    setLoadingDashboard(true)
    try {
      setDashboardData(await new Api().getDashboard(searchParams()))
    } catch (e) {
      console.log(e)
      setError(e.message || e)
    }
  }
  const getTractData = async () => {
    resetGenerate()
    setLoadingTractData(true)
    try {
      await Promise.all([
        setTractData(await new Api().getProcTractData({year, geoid: searchResult.tract})),
        setCountyData(await new Api().getProcCountyData({year, geoid: searchResult.county})),
      ])
    } catch (e) {
      console.log(e)
      setError(e.message || e)
    }

    setLoadingTractData(false)
  }

  return (
    <div className="Search">
      <h5 className="card-title">
        Search by year and address or longitude/latitude
      </h5>
      <form className="row gy-2 gx-3 align-items-center">
        <div className="col-md-6 col-12">
          <label className="form-label" htmlFor="year">Year (required)</label>
          {years && years.length > 0 &&
            <select className="form-select" name="year" value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              {years && ([''].concat(years)).map((year) => {
                return <option key={year} value={year}>{year}</option>
              })}
            </select>
          }
          {years && years.length <= 0 &&
            <input className="form-control" type="text"
              value="Loading years..." disabled readOnly
            />
          }
        </div>
        <div className="col-md-6 col-12">
          <label className="form-label" htmlFor="id">ID (required)</label>
          <input type="text" className="form-control" placeholder="Enter an ID"
            onChange={(e) => setId(e.target.value)}
            value={id}
          />
        </div>
        <div className="col-md-6 col-12">
          <label className="form-label" htmlFor="address">
            Select by Address
          </label>
          <div className="input-group">
            <input type="text" className="form-control"
              placeholder="Address to search" id="address" name="address"
              value={address}
              onChange={(e) => (setAddress(e.target.value))}
            />
            <button className="btn btn-primary" type="button"
              disabled={!year || year === '' || !id || id === ''}
              onClick={searchAddress}
            >
              Search
            </button>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <label className="form-label" htmlFor="address">
            Select by Lon/Lat
          </label>
          <div className="input-group">
            <span className="input-group-text">Lon</span>
            <input type="text" className="form-control"
              value={lon} onChange={(e) => setLon(e.target.value)}
            />
            <span className="input-group-text">Lat</span>
            <input type="text" className="form-control"
              value={lat} onChange={(e) => setLat(e.target.value)}
            />
            <button className="btn btn-primary" type="button"
              disabled={!year || year === '' || !id || id === ''}
              onClick={searchLatLon}
            >
              Search
            </button>
          </div>
        </div>
      </form>
      { searching &&
        <Loading message="Searching location"/>
      }
      { error && <ErrorPane error={error} /> }
      { searchResult &&
        <div className="card mt-4">
          <div className="card-body">
            <h5 className="card-title">
              Geocoding Results
            </h5>
            {(searchResult.county || searchResult.tract) &&
              <>
                <div className="row">
                  <div className="col-md-3 text-md-end">
                    <strong>Year</strong>
                  </div>
                  <div className="col-md-9">
                     {year}
                  </div>
                </div>
                { searchResult.y &&
                  <div className="row">
                    <div className="col-md-3 text-md-end">
                      <strong>Latitude</strong>
                    </div>
                    <div className="col-md-9">
                       {searchResult.y}
                    </div>
                  </div>
                }
                { searchResult.x &&
                  <div className="row">
                    <div className="col-md-3 text-md-end">
                      <strong>Longitude</strong>
                    </div>
                    <div className="col-md-9">
                       {searchResult.x}
                    </div>
                  </div>
                }
                <div className="row">
                  <div className="col-md-3 text-md-end">
                    <strong>County</strong>
                  </div>
                  <div className="col-md-9">
                     {searchResult.county}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 text-md-end">
                    <strong>Tract</strong>
                  </div>
                  <div className="col-md-9">
                     {searchResult.tract}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 text-md-end">
                  </div>
                  <div className="col-md-9">
                    <button type="button" className="btn btn-primary mt-2 me-2"
                      onClick={getTractData}
                    >
                      Generate processed data CSV
                    </button>
                    <button type="button" className="btn btn-primary mt-2 me-2"
                      onClick={generateDashboard}
                    >
                      Generate dashboard PDF
                    </button>
                  </div>
                </div>
              </>
            }
            {!(searchResult.county || searchResult.tract) &&
              <div className="alert alert-warning" role="alert">
                {searchResult}
              </div>
            }
          </div>
        </div>
      }
      {loadingDashboard &&
        <Loading message="Generating PDF dashboard, please wait..." />
      }
      {dashboardData && isPdfData(dashboardData) &&
        <div className="card mt-4">
          <div className="card-body">
            <h5 className="card-title">
              PDF dashboard is ready
            </h5>
            <a className="btn btn-primary"
              download={`${year}-${id}.pdf`}
              href={`data:application/pdf;base64,${dashboardData.body}`}
            >
              Download {`${year}-${id}.pdf`}
            </a>
          </div>
        </div>
      }
      {loadingTractData &&
        <Loading message="Loading CSV data, please wait..." />
      }
      {tractData && countyData &&
        <div className="card mt-4">
          <div className="card-body">
            <h5 className="card-title">
              CSV data are ready
            </h5>
            <CSVLink
              className="btn btn-primary mt-2 me-2"
              download={`${year}-${id}.csv`}
              data={[tractData]}
              target="_blank"
            >
              Download tract CSV
            </CSVLink>
            <CSVLink
              className="btn btn-primary mt-2 me-2"
              download={`${year}-${id}.csv`}
              data={[countyData]}
              target="_blank"
            >
              Download county CSV
            </CSVLink>
          </div>
        </div>
      }
    </div>
  )

}

export default Search
