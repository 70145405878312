import { PDFDocument, PDFPage } from "pdf-lib"

import { Api } from '../Api'

export async function getDashboardUrl({locations}) {
  const api = new Api()
  const batchId = (await api.launchMultiDash({locations})).batch_id
  let result;
  while (!result) {
    await new Promise(r => setTimeout(r, 5000))
    let response = await api.getSignedUrl({batchId})
    if (response.signed_url) {
      result = response.signed_url
      break;
    }
  }
  return result;
}

export async function downloadPdf({id,lat,lon,year}) {
  let result;
  while (!result) {
    let response = await new Api().getDashboard({id,lat,lon,year})
    if (response.headers && response.headers["Content-type"] === "application/pdf") {
      result = response
    } else {
      await new Promise(r => setTimeout(r, 5000))
    }
  }
  return result;
}

export async function mergePdfs(pdfsToMerge) {
  const mergedPdf: PDFDocument = await PDFDocument.create();

  const createInnerPromise = async (arrayBuffer) => {
    const pdf: PDFDocument = await PDFDocument.load(arrayBuffer);
    return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  };

  const outerPromise = pdfsToMerge.map((arrayBuffer) => {
    const innerPromise = createInnerPromise(arrayBuffer);
    return innerPromise;
  });

  const resultOuterPromise = await Promise.all(outerPromise);

  resultOuterPromise.forEach((pageArray) => {
    pageArray.forEach((page: PDFPage) => {
      mergedPdf.addPage(page);
    });
  });

  return (await mergedPdf.save()).buffer;
}

export function arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}