const ErrorPane = ({
  error
}) => {
  return (
    <div className="alert alert-danger my-3" role="alert">
      <p>
        {(error || error.message) ? 
          (error.message || error) : 
          'An unknown error occurred communicating with the API.'
        }
      </p>
      Please try again later.
    </div>
  )
}

export default ErrorPane