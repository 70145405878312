const Contact = () => {
  return (
    <div className="contact-page">
      <h2>Contact Information</h2>
      <p>If you have questions, feedback, or suggestions please fill out the <a href="https://docs.google.com/forms/d/e/1FAIpQLSfj4VPwOJwm0hOQJaKaI-tZ6PcoW2MMvtsgSDjttDWu49PE0Q/viewform?usp=sf_link">issue reporting form</a>. For any other concerns please email Michael Kramer at <a href="mailto:mkram02@emory.edu">mkram02@emory.edu</a>.</p>
    </div>
  )
}

export default Contact;
