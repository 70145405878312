const DocList = ({lead, items}) => (
  <>
    <p className="lead">{lead}</p>
    <div className="row mb-4">
      {items.map(({text, file, url, narrated}, index) => {
        const bg = index % 2 == 1 ? 'bg-light' : ''
        const link = file ? `/files/${file}` : url
        return (
          <>
            <div className={`col-md-12 col-lg-6 text-lg-end pb-1 pt-2 ${bg}`}>
              <strong>{text}</strong>
            </div>
            {link &&
              <div className={`col-md-12 col-lg-6 pb-1 pt-1 ${bg}`}>
                <a href={link} download>
                  {file || url}
                </a>
                {narrated &&
                  <em>
                    &nbsp;(<a href={narrated} target="_blank">Narration</a>)
                  </em>
                }
              </div>
            }
          </>
        )
      })}
    </div>
  </>
)

const Resources = () => {
  return (
    <div className="documentation-page">
      <h2>Resources</h2>
      <p>These resources have been developed to support maternal mortality review committee leadership, staff, and members in incorporating the Community Vital Signs resources into the review process. These documents are under continual review and will be updated intermittently.</p>
      <p><b>Clicking on file links below will download file. Check your downloads after clicking.</b></p>
      <hr className="m-5"/>

      <DocList
        lead="Is your MMRC Ready? Resources to assess and prepare"
        items={[
          {
            text: 'Assessing MMRC Readiness (Powerpoint)',
            file: '0101-assessing-mmrc-readiness-v2.pptx',
            narrated: 'https://youtu.be/4LEP0gSiPtg'
          },
          {
            text: 'CVS Readiness Checklist (document)',
            file: '0102-CVS-readiness-checklist-v2.docx'
          }
        ]}
      />

      <DocList
        lead="Introduction to the Community Vital Signs Project"
        items={[
          {
            text: 'Overview of the CVS Project (Powerpoint)',
            file: '0201-cvs-introduction-v2.pptx',
            narrated: 'https://youtu.be/DKHLK2Vr4w4'
          },
          {
            text: 'Causes of inequalities in maternal mortality (Powerpoint)',
            file: '0202-causes-of-inequities-v2.pptx',
            narrated: 'https://youtu.be/0YR93ZNSB70'
          },
          {
            text: 'CVS and the "chain of risk" (Powerpoint)',
            file: '0203-chain-of-risks-v1.pptx'
          }
        ]}
      />

      <DocList
        lead="Using the Community Vital Signs Dashboards"
        items={[
          {
            text: 'Understanding the CVS data indicators (Powerpoint)',
            file: '0301-understanding-cvs-data-v2.pptx',
            narrated: 'https://youtu.be/9H_nhxQC7EY'
          },
          {
            text: 'How to interpret the CVS dashboard (Powerpoint)',
            file: '0302-interpreting-cvs-dashboard-v2.pptx',
            narrated: 'https://youtu.be/ou8ber7CxsI'
          },
          {
            text: 'CVS Dashboard interpretation cheatsheet (Powerpoint)',
            file: '0303-CVS-dashboard-cheatsheet-v2.pdf'
          },
          {
            text: 'Retrieving CVS Dashboards (Powerpoint)',
            file: '0304-retrieving-cvs-dashboards-v2.pptx',
            narrated: 'https://youtu.be/ZrGCt0IDKcc'
          },
          {
            text: 'Data dictionary for CVS indicators (document)',
            file: '0305-CVS-data-dictionary-v5.xlsx'
          }
        ]}
      />

      <DocList
        lead="Using the CVS Recommendations Reference tools"
        items={[
          {
            text: 'Introduction to the CVS Recommendation Reference (Powerpoint)',
            file: '0401-intro-cvs-recommendation-reference-v2.pptx',
            narrated: 'https://youtu.be/kzP-nHCzgnI'
          },
          {
            text: 'Overview for using the recommendation reference (document)',
            file: '0402-overview-recommendation-reference-resources-v2.pdf'
          },
          {
            text: 'Maternal health literature review Part 1 (document)',
            file: '0403-maternal-lit-review-part1-v2.pdf'
          },
          {
            text: 'Maternal health literature review Part 2 (document)',
            file: '0404-maternal-lit-review-part2-v2.xlsx'
          },
          {
            text: 'RWJF Community Health recommendations (document)',
            file: '0405-RWJF-roadmap-recommendations-v2.xlsx'
          }
        ]}
      />

      <DocList
        lead="Resources for abstractors and analysts"
        items={[
          {
            text: 'Translating the CVS dashboard to case narratives (Powerpoint)',
            file: '0501-abstractor-narrative-cvs-dashboard-v2.pptx',
            narrated: 'https://youtu.be/XcSgEpB_4Tw'
          },
          {
            text: 'CVS Narrative template (document)',
            file: '0502-Community Vital Sign Narrative Template-v6.pdf'
          },
          {
            text: 'Incorporating CVS indicators into aggregate reports (Forthcoming)'
          }
        ]}
      />

      <DocList
        lead="Additional resources"
        items={[
          {
            text: 'Kramer MR, Strahan AE, Preslar J, Zaharatos J, St Pierra A, Grant JE, Davis NL, Goodman DA, Callaghan WM. Changing the conversation: applying a health equity framework to maternal mortality reviews. AJOG. 2019;221(6):609.e1-609.e9',
            url: 'https://www.ajog.org/article/S0002-9378(19)31104-4/pdf'
          },
          {
            text: 'Barrera C, Kramer MR, Merkt PT, Petersen EE, Brantley MD, Eckhaus L, Beauregard JL, Goodman DA. County-Level Associations Between Pregnancy-Related Mortality Ratios and Contextual Sociospatial Indicators.Obstetrics & Gynecology. 2022;139(5):855-865',
            url: 'https://journals.lww.com/greenjournal/Fulltext/9900/County_Level_Associations_Between.435.aspx'
          }
        ]}
      />
      <h4>Page last updated:</h4>
      <p>January 9, 2024</p>
      
    </div>
  )
}

export default Resources;