import { useEffect, useState } from 'react'
import { Link } from "react-router-dom"

import { Api } from '../Api'

import Search from './Search'
import State from './State'
import Multiple from './Multiple'
import Loading from './Loading'

const upMessage = 'Server is up'
const initialMessage = 'initial'
const warmingMessage = 'warming'
const retryInterval = 10 * 1000

const Unavailable = ({status}) => (
  <div className="alert alert-warning" role="alert">
    Message from data services: {status}.  Will retry.
  </div>
)

const Downloads = ({page}) => {

  const [ status, setStatus ] = useState(initialMessage)
  const [ reconnectTry, setReconnectTry ] = useState(0)

  const isUp = () => status && status.indexOf(upMessage) > -1
  const isWarming = () => status && (
    status.indexOf(warmingMessage) > -1 ||
    status.indexOf(initialMessage) > -1
  )
  const isDown = () => !isUp() && !isWarming()

  const fetchData = async () => {
    try {
      setStatus(await new Api().serverStatus())
      setReconnectTry(reconnectTry + 1)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (isWarming()) setTimeout(fetchData, retryInterval)
  }, [status, reconnectTry])

  return (
    <div className="Home">
      {!status || isWarming() && <Loading message="Connecting to service, please allow up to 1 minute for the service to warm up."/>}
      {status && isDown() && <Unavailable status={status} />} 
      {isUp() &&
        <>
          <ul className="nav nav-tabs mb-4">
            <li className="nav-item">
              <Link className={`nav-link ${page == 'single' ? 'active' : ''}`}
                aria-current="page" to="/single"
               >
                 Single
               </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${page == 'multiple' ? 'active' : ''}`}
                aria-current="page" to="/multiple"
               >
                 Multiple
               </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${page == 'state' ? 'active' : ''}`}
                aria-current="page" to="/state"
               >
                 State
               </Link>
            </li>
          </ul>
          {page === 'single' &&
            <Search />
          }
          {page === 'multiple' &&
            <Multiple />
          }
          {page === 'state' &&
            <State />
          }
        </>
      }
    </div>
  )
}

export default Downloads;
