import { useEffect, useState } from 'react'
import { CSVLink } from "react-csv"

import { Api } from '../Api'
import { getDashboardUrl, mergePdfs, arrayBufferToBase64 } from '../lib/PdfService'

import ErrorPane from './ErrorPane'

const table = 'acs-tract'

const Multiple = () => {

  const [ years, setYears ] = useState([])
  useEffect(() => {
    const fetchData = async () => setYears(await new Api().getYears({table}))
    fetchData().catch(console.error)
  }, [])

  const [ loading, setLoading ] = useState(false)
  const [ dataUrl, setDataUrl ] = useState()
  const [ error, setError ] = useState()

  const [ year, setYear ] = useState('')
  const [ text, setText ] = useState('')

  const loadData = async () => {
    const id = Math.random(99999999)
    let isLocationError = false
    setError()
    setDataUrl()
    setLoading(true)
    let coords = []
    const lines = text.split('\n')
    lines.forEach((line, index) => {
      const coord = line.split(',')
      const lon = parseFloat((coord[0] || ''))
      const lat = parseFloat((coord[1] || ''))
      const id = coord[2] ? coord[2].trim() : (index + 1)
      if (isNaN(lon) || isNaN(lat)) {
        setError(`Unable to parse line "${line}"`)
        isLocationError = true
      }
      coords.push({lon, lat, id})
    })
    if (!isLocationError) {
      try {
        setDataUrl(await getDashboardUrl({
          locations: coords.map(({lon, lat, id}) => ({id, lat, lon, year}))
        }))
        setLoading(false)
      } catch (e) {
        console.log(e)
        setLoading(false)
        setError(`Error loading data from service: ${e.message}`)
      }
    } else {
      setLoading(false)
    }
  }

  return (
    <div className="cvs-multiple">
      <div className="row">
        <div className="col-md-6">
          <p>Search for multiple county/tracts at once.  Enter one longitude and latitude in decimals per line with an optional ID/Name, separated by a comma: e.g.</p>
        </div>
        <div className="col-md-6">
          <p className="font-monospace">-77.092348, 38.2345, Location 1<br/>-77.092348, 38.2345, Location 2</p>
        </div>
      </div>
      <hr/>
      <div className="mb-3">
        <div className="row g-3">
          <div className="col">
            <label className="form-label" htmlFor="year">Year (required)</label>
            {years && years.length > 0 &&
              <select className="form-select" name="year" 
                disabled={!years || loading} value={year} 
                onChange={(e) => setYear(e.target.value)}
              >
                {years && ([''].concat(years)).map((year) => {
                  return <option key={year} value={year}>{year}</option>
                })}
              </select>
            }
            {years && years.length <= 0 &&
              <input className="form-control" type="text" 
                value="Loading years..." disabled readOnly
              />
            }
          </div>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="locations" className="form-label">Comma-Separated Longitude, Latitude, ID/Name values</label>
        <textarea className="form-control" id="locations" rows="8" value={text} onChange={(e) => setText(e.target.value)} disabled={loading} />
      { !loading &&
        <button className="btn btn-primary mt-2" onClick={loadData}
          disabled={!year || year === ''}
        >
          Generate data for locations
        </button>
      }
      { loading &&
        <button className="btn btn-primary mt-2"  disabled>
          <svg className="svg-loader" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="45"/>
          </svg>
          Generating data, please do not leave this page...
        </button>
      }
      </div>
      { error && <ErrorPane error={error} /> }
      { dataUrl &&
        <div className="card mt-4">
          <div className="card-body">
            <h5 className="card-title">
              CSV data are ready
            </h5>
            <a className="btn btn-primary mt-2 me-2"
              download={`${year}-data.zip`}
              href={dataUrl}
            >
              Download zip file of dashboards and data
            </a>
          </div>
        </div>
      }
    </div>
  )
}

export default Multiple;
