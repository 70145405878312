import axios from 'axios'
import  {
  apiUrl,
  apiId,
  apiSecret
} from './Config'

const DefaultTimeout = 30 * 1000; // 30 seconds

const returnSample = async (timeout, data) => {
  return await new Promise(res => setTimeout(() => {res(data)}, timeout));
}

const runApiCall = async ({operation, payload, timeout}) => {
  return (await axios.post(apiUrl, 
    {
      id: apiId,
      secret: apiSecret,
      operation,
      payload
    },{
      timeout: timeout || DefaultTimeout
    }
  )).data
}

export class Api {

  async serverStatus() {
    return await runApiCall({
      operation: "server-status",
      payload: ""
    })
  }

  async geocode({year, lat, lon}) {
    return await runApiCall({
      operation: "geocode",
      payload: {year, lat: `${lat}`, lon: `${lon}`}
    })
  }

  async geocodeAddress({year, address, timeout}) {
    return await runApiCall({
      operation: "geocode-address",
      payload: {year, address},
      timeout
    })
  }

  async getYears({table}) {
    return await runApiCall({
      operation: "get-years",
      payload: {table}
    })
  }

  async getStates({table, year}) {
    return (await runApiCall({
      operation: "get-states",
      payload: {table, YEAR: year}
    })).map((s) => s.replaceAll('\"', ''))
  }

  async getGeoids({table, year, state}) {
    return (await runApiCall({
      operation: "get-geoids",
      payload: {table, YEAR: year, state}
    })).map((s) => s.replaceAll('\"', ''))
  }

  async getAcsTractData({geoid, year}) {
    return await runApiCall({
      operation: "get-acs-tract-data",
      payload: {GEOID: geoid, YEAR: year}
    })
  }

  async getNonAcsData({geoid, year}) {
    return await runApiCall({
      operation: "get-non-acs-data",
      payload: {GEOID: geoid, YEAR: year}
    })
  }

  async getAcsCountyData({geoid, year}) {
    return await runApiCall({
      operation: "get-acs-county-data",
      payload: {GEOID: geoid, YEAR: year}
    })
  }

  async getProcTractData({geoid, year}) {
    return await runApiCall({
      operation: "get-proc-tract-data",
      payload: {GEOID: geoid, YEAR: year}
    })
  }

  async getProcCountyData({geoid, year}) {
    return await runApiCall({
      operation: "get-proc-county-data",
      payload: {GEOID: geoid, YEAR: year}
    })
  }

  async getDashboard({id,lat,lon,year}) {
    return await runApiCall({
      operation: "get-dashboard",
      payload: {lat:`${lat}`,lon:`${lon}`,year,id}
    })
  }

  // Multi-calls

  async launchMultiDash({locations}) {
    return await runApiCall({
      operation: "launch-multi-dash",
      payload: {locations: locations.map(({id, lat, lon, year}) => ({
        id: `${id}`, year: `${year}`, lat: `${lat}`, lon: `${lon}`
      }))}
    })
  }

  async getSignedUrl({batchId}) {
    return await runApiCall({
      operation: "get-signed-url",
      payload: {batch_id: batchId}
    })
  }

  async getAllData({year, countyGeoid, tractGeoid}) {
    return await runApiCall({
      operation: "get-all-data",
      payload: {year, c_geoid: countyGeoid, t_geoid: tractGeoid}
    })
  }

  async getStateData({year, state}) {
    return await runApiCall({
      operation: "get-state-data",
      payload: {year, state}
    })
  }

  // Aggregate calls

  async getAllGeocodes(coords) {
    const data = []
    await Promise.all(
      coords.map(async ({year, lon, lat}) => {
        return data.push(await this.geocode({year, lon, lat}))
      })
    )
    return data
  }

  async getAllProcTractData({year, tractIds}) {
    const data = []
    await Promise.all(
      tractIds.map(async (tractId) => {
        return data.push(await this.getProcTractData({year, geoid: tractId}))
      })
    )
    return data
  }

  async getAllProcCountyData({year, countyIds}) {
    const data = []
    await Promise.all(
      countyIds.map(async (countyId) => {
        return data.push(await this.getProcCountyData({year, geoid: countyId}))
      })
    )
    return data
  }

}
